import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SignOut } from "phosphor-react";

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear session and local storage
    localStorage.clear();
    sessionStorage.clear();
    
    // Redirect to the home page
    navigate('/');
  };

  return (
    <>
    <a onClick={handleLogout} className="is-size-5">
      <span className="icon-text">
        <span className="icon">
          <SignOut weight="regular" size={24} className='has-text-info' />
        </span>
        <span>Logout</span>
      </span>
    </a>
    </>
  );
};

export default LogoutButton;