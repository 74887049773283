import React from 'react';

const CustomCheckbox = ({ id, label, checked, onChange }) => {
  // Use Bulma's control and checkbox classes for base styling
  // Then, apply additional inline styles for size and color enhancements
  return (
    <div className="field">
      <label className="checkbox title is-6" htmlFor={id} style={{ alignItems: 'center', display: 'flex' }}>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="mr-2" // Add some spacing between the checkbox and label
          style={{
            width: '20px', // Larger checkbox
            height: '20px', // Keep the aspect ratio square
            accentColor: '#3e8ed0', // Bulma primary color for the checkmark and border
          }}
        />
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
