import React from 'react';
import { Navigate } from 'react-router-dom';
import UseAuthStatus from '../Hooks/UseAuthStatus'; // Adjust path as necessary
import FullScreenLoader from './FullScreenLoader/FullScreenLoader';

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = UseAuthStatus();

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
};

export default ProtectedRoute;