import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const PasswordResetPage = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetToken, setResetToken] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('resetToken');
    if (token) {
      setResetToken(token);
      // Make an API call to get the email associated with the token
      axios.get(`/api/get-email?resetToken=${token}`)
        .then(response => {
          setEmail(response.data.email); // Assuming the API responds with the email
        })
        .catch(error => console.error('Error fetching email:', error));
    }
  }, [searchParams]);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // Implement API call to request password reset
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    // Implement API call to reset the password using resetToken and newPassword
  };

  return (
    <div className="PasswordReset">
      {resetToken ? (
        // Render form to set new password
        <form onSubmit={handlePasswordReset}>
          <p>Email: {email}</p>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
            required
          />
          <button type="submit">Reset Password</button>
        </form>
      ) : (
        // Render form to request password reset link
        <form onSubmit={handleEmailSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email"
            required
          />
          <button type="submit">Request Reset Link</button>
        </form>
      )}
    </div>
  );
};

export default PasswordResetPage;