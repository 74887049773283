import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate hook
import Layout from "./Layout";
import axios from "axios";
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TicketPage = () => {
  const navigate = useNavigate();
  const { siteId, ticketIdentifier } = useParams(); // Destructure siteId and identifier from useParams
  const [loading, setLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [allowDeletion, setAllowDeletion] = useState(false);
  const [deletedBy, setDeletedBy] = useState(null);
  const [deletedAt, setDeletedAt] = useState(null);
  const [isDeleteConfirmationModalActive, setIsDeleteConfirmationModalActive] = useState(false);
  const [ticket, setTicket] = useState({
    identifier: null,
    accessCode: null,
    additionalInformation: null,
    apbEnabled: false,
    attractionId: null,
    entered: false,
    prepaidAmount: null,
    resetApb: false,
    numberOfUse: null,
    validityFrom: null,
    validityFromGmt: null,
    validityTo: null,
    validityToGmt: null,
    medias: [],
    siteId: null
  });

  useEffect(() => {
    const fetchTicket = async () => {
      if (ticketIdentifier && siteId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/ticket/${siteId}/${ticketIdentifier}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json"
            },
          });
          const ticketData = response.data.ticket;
          ticketData.medias = ticketData.medias || [];
          setTicket(ticketData);
          setLoading(false);
        } catch (error) {
          console.error("Failed to fetch ticket:", error);
          toast.error('Failed to fetch ticket. ' + error.message);
          navigate("/search");
        }
      } else {
        toast.warning('No ticket identifier or site ID provided, redirected to search page.');
        navigate("/search");
      }
    };

    fetchTicket();
  }, [siteId, ticketIdentifier, navigate]);

  // Check if requested ticket has been deleted
  useEffect(() => {
    const checkIfDeleted = async () => {
      if (ticketIdentifier && siteId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/ticket/isdeleted/${siteId}/${ticketIdentifier}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json"
            },
          });
          setIsDeleted(response.data.isDeleted);
          if(response.data.isDeleted){
            setDeletedAt(response.data.deletedTicket.deletedAt)
            setDeletedBy(response.data.deletedTicket.deletedByUserEmail)
          }
        } catch (error) {
          console.error("Failed to check if ticket is deleted:", error);
          toast.error('Failed to check deletion status.');
        }
      }
    };
  
    checkIfDeleted();
  }, [siteId, ticketIdentifier]);

  // Convert timestamp to YYYY-MM-DD format for input[date] value
  const formatDateForInput = (timestamp) => {
    return new Date(timestamp).toISOString().split("T")[0];
  };

  const handleValidityChange = (event, key) => {
    setTicket({
      ...ticket,
      [key]: new Date(event.target.value).getTime(),
    });
  };

  const handlePlateChange = (event) => {
    const newPlateIdentifier = event.target.value;
    const newMedias = ticket.medias.slice(); // Create a shallow copy of the array
    const plateIndex = newMedias.findIndex(media => media.type === "plate");

    if (plateIndex !== -1) {
      // Update existing plate identifier
      newMedias[plateIndex].identifier = newPlateIdentifier;
    } else {
      // Add new plate media if not found
      newMedias.push({ type: "plate", identifier: newPlateIdentifier });
    }

    setTicket({ ...ticket, medias: newMedias });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_ADDRESS}/ticket`,
        { ticket },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json"
          },
        }
      );
      
      console.log("Ticket saved successfully:", response.data);
      toast.success('Ticket saved');
    } catch (error) {
      console.error("Failed to save ticket:", error);
      toast.error('Failed to save ticket. ', error.message);
    } finally {
      setLoading(false);
    }
  };  

  const showDeleteConfirmationModal = () => setIsDeleteConfirmationModalActive(true);
  const hideDeleteConfirmationModal = () => setIsDeleteConfirmationModalActive(false);
  const deleteTicket = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_ADDRESS}/ticket`, {
        withCredentials: true,
        params: {
          siteId,
          ticketIdentifier
        },
        headers: {
          Accept: "application/json"
        },
      });

      if (response.status === 200) {
        toast.success(`Ticket deleted: ${ticketIdentifier}`);
        navigate(`/search`);
      } else {
        throw new Error('Failed to delete ticket');
      }
    } catch (error) {
      toast.error('Failed to delete ticket');
    } finally {
      hideDeleteConfirmationModal();
    }
  };

  function getUserFriendlyDate(mongoDate){
    let date = new Date(mongoDate);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formattedDate;
  }

  if (loading) {
    return (
      <Layout>
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-8">
                <FullScreenLoader isFullScreen={false} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  const barcode = ticket.medias.find(media => media.type === "barcode")?.identifier || "";
  const plateNumber = ticket.medias.find(media => media.type === "plate")?.identifier || "";

  return (
    <Layout>
      <div className="container">
        <div className="section">

          {/* DELETE CONFIRMATION MODAL */}
          <div>
            <div className={`modal ${isDeleteConfirmationModalActive ? 'is-active' : ''}`}>
              <div className="modal-background"></div>
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">Confirm deletion</p>
                  <button className="delete" aria-label="close" onClick={hideDeleteConfirmationModal}></button>
                </header>
                <section className="modal-card-body">
                  Are you sure you want to delete ticket {ticket.identifier}? This action cannot be undone.
                </section>
                <footer className="modal-card-foot">
                  <button className="button is-danger" onClick={deleteTicket}>Delete</button>
                  <button className="button" onClick={hideDeleteConfirmationModal}>Cancel</button>
                </footer>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-8">
              <div className="columns is-multiline">
                <div className="column is-12">        
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item">
                      {
                        isDeleted ? (
                          <h3 className="title is-4">View ticket</h3>
                        ) : (
                          <h3 className="title is-4">Edit ticket</h3>
                        )
                      }
                      </div>
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                      { allowDeletion && !isDeleted && (
                        <button className="button is-danger" onClick={showDeleteConfirmationModal}>Delete ticket</button>
                      )}
                      </div>
                    </div>
                  </div>  
                  {isDeleted && (
                    <div className="notification is-danger is-fullwidth">
                      This ticket was deleted by {deletedBy} on {getUserFriendlyDate(deletedAt)} and may only be viewed.
                    </div>
                  )}
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Identifier</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        disabled
                        value={ticket.identifier}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Number of uses</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        disabled
                        value={ticket.numberOfUse}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-12">
                  <div className="field">
                    <label className="label">Additional information</label>
                    <textarea className="textarea" rows={2} disabled value={ticket.additionalInformation ?? ""}></textarea>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Valid from:</label>
                    <div className="control">
                      <input
                        className="input"
                        type="date"
                        disabled={isDeleted}
                        value={formatDateForInput(ticket.validityFrom)}
                        onChange={(event) =>
                          handleValidityChange(event, "validityFrom")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Valid until midnight GMT of:</label>
                    <div className="control">
                      <input
                        className="input"
                        type="date"
                        disabled={isDeleted}
                        value={formatDateForInput(ticket.validityTo)}
                        onChange={(event) =>
                          handleValidityChange(event, "validityTo")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-8">
              <div className="columns">
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Barcode</label>    
                    <div className="field has-addons has-addons-centered">
                      <p className="control is-expanded">
                        <input
                          className="input"
                          type="text"
                          placeholder="Barcode"
                          value={barcode}
                          disabled={true}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Plate number</label>    
                    <p className="control is-expanded">
                      <input
                        className="input"
                        type="text"
                        placeholder="Plate Number"
                        value={plateNumber}
                        onChange={handlePlateChange}
                        disabled={isDeleted}
                      />
                    </p>
                  </div>
                </div>
              </div>      
              {!isDeleted && (
                <>
                <hr />
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      
                    </div>
                  </div>
                  <div className="level-right">
                    <div className="level-item">
                      <button className="button is-info" onClick={handleSave}>
                        Save ticket
                      </button>
                    </div>
                  </div>
                </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TicketPage;