import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import { toast } from "react-toastify";

const SiteEditPage = () => {
  const { id } = useParams(); // To access the site ID from the URL
  const navigate = useNavigate();

  const [site, setSite] = useState({
    name: "",
    jms_server_address: "",
    jms_server_port: "",
    jms_server_username: "",
    jms_server_password: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSite = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}/site/${id}`,
          {
            withCredentials: true
          }
        );
        setSite(response.data);
      } catch (err) {
        console.error("Failed to fetch site details:", err);
        setError("Failed to load site details. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSite();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSite((prevSite) => ({
      ...prevSite,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_ADDRESS}/sites/${id}`, site, {
        withCredentials: true
      });
      toast.success("Site updated successfully");
      navigate("/admin/sites"); // Redirect to the sites list page after successful update
    } catch (err) {
      console.error("Failed to update site:", err);
      setError("Failed to update site. Please try again.");
      toast.error("Failed to update site. ", err.message);
    }
  };

  return (
    <Layout>
      <div className="section">
        <div className="container">
          {isLoading ? (
            <FullScreenLoader />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="columns">
              <div className="column is-5">
                <form onSubmit={handleSubmit}>
                  <h3 className="title is-4">Editing site: {site.name}</h3>
                  <div className="field">
                    <label className="label">Site Name</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="name"
                        value={site.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">JMS Server Address</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="jms_server_address"
                        value={site.jms_server_address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">JMS Server Port</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        name="jms_server_port"
                        value={site.jms_server_port}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">JMS Server Username</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="jms_server_username"
                        value={site.jms_server_username}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">JMS Server Password</label>
                    <div className="control">
                      <input
                        className="input"
                        type="password"
                        name="jms_server_password"
                        value={site.jms_server_password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="control">
                    <button type="submit" className="button is-info">
                      Update Site
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SiteEditPage;
