import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import CustomCheckbox from "./CustomCheckbox";
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import { toast } from "react-toastify";

const UserEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    role: "",
    sites: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [allSites, setAllSites] = useState([]); // If managing site access

  useEffect(() => {
    const fetchUserAndSites = async () => {
      setIsLoading(true);
      try {
        // Fetch user details
        const userDetails = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}/users/${id}`,
          {
            withCredentials: true,
          }
        );
        setUser(userDetails.data);

        // Optionally, fetch all sites if managing site access
        const sitesDetails = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}/sites`,
          { withCredentials: true }
        );
        setAllSites(sitesDetails.data.sites);
      } catch (err) {
        console.error("Failed to fetch user or sites:", err);
        setError(
          "Failed to load user or sites details. Please try again later."
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserAndSites();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSiteChange = (siteId) => {
    setUser((prevUser) => ({
      ...prevUser,
      sites: prevUser.sites.includes(siteId)
        ? prevUser.sites.filter((id) => id !== siteId)
        : [...prevUser.sites, siteId],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(user);
      await axios.patch(
        `${process.env.REACT_APP_API_ADDRESS}/users/${id}`,
        user, // The data payload goes here
        { withCredentials: true } // Configuration object as the last argument
      );
      
      navigate("/admin/users"); // Redirect to users list after successful update
      toast.success("User updated successfully");
    } catch (err) {
      console.error("Failed to update user:", err);
      setError("Failed to update user. Please try again.");
      toast.error("Failed to update user");
    }
  };

  return (
    <Layout>
      <div className="section">
        <div className="container">
          <h3 className="title is-4">Edit user</h3>
          {isLoading ? (
            <FullScreenLoader />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="columns">
              <div className="column is-5">
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        className="input"
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Role</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="role"
                        value={user.role}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* Optionally, manage site access */}
                  <div className="field">
                    <label className="label">Site access</label>
                    {allSites.map((site) => (
                      <div className="field">
                        <CustomCheckbox
                          id={site._id}
                          label={site.name}
                          checked={user.sites.includes(site._id)}
                          onChange={() => handleSiteChange(site._id)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="control">
                    <button type="submit" className="button is-info">
                      Update user
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default UserEditPage;
