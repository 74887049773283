import React, { useState, useEffect } from "react";
import axios from "axios";
import "bulma/css/bulma.min.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Extract error message from URL query parameters
    const params = new URLSearchParams(window.location.search);
    const errorParam = params.get('error');
    const message = params.get('message');
    
    if (errorParam && message) {
      setError(decodeURIComponent(message));
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let emailToFind = email.toLowerCase();
      const response = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/login`,
        { withCredentials: true },
        { 
          email: emailToFind, 
          password 
        }
      );
      window.location.href1 = "/search";
    } catch (error) {
      setError("Please try again");
      console.error(
        "Login error:",
        error.response?.data?.error || error.message
      );
    }
  };

  const handleSingleSignOnLogin = () => {
    //window.location.href = `${process.env.REACT_APP_API_ADDRESS}/auth/saml`;
    window.location.href = `${process.env.REACT_APP_API_ADDRESS}/auth/oidc`;
  };

  return (
    <>
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              <figure className="avatar">
                <img
                  src="/merlin-logo.png"
                  style={{ height: "150px", width: "auto", marginBottom: "2rem" }}
                  alt="avatar"
                />
              </figure>
              {error && (
                <div className="message py-5 has-text-danger is-danger">
                  {error}
                </div>
              )}
              <button
                onClick={handleSingleSignOnLogin}
                className="button is-block is-info is-large is-fullwidth mt-6"
              >
                Login with your Merlin account
              </button>
              {/*
              <hr className="login-hr" />
              <p style={{marginBottom: "1rem"}}>or login below using a local account</p>
              <div className="box">
                <form onSubmit={handleLogin}>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="email"
                        placeholder="Email address"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="button is-block is-info is-large is-fullwidth"
                  >
                    Login<i className="fa fa-sign-in" aria-hidden="true"></i>
                  </button>
                </form>
              </div>
              <p className="has-text-grey">
                <a href="#/password-reset">Forgot Password</a> &nbsp;·&nbsp;
                <a href="../">Need Help?</a>
              </p>
              */ }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
