import axios from 'axios';
import { useState, useEffect } from 'react';

const UseAuthStatus = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateSession = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/auth/validate`, { withCredentials: true });
        setIsAuthenticated(response.data.isAuthenticated);
        setUserEmail(response.data.userEmail);
        setUserRole(response.data.userRole);
      } catch (error) {
        console.error('Session validation error:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    validateSession();
  }, []);

  return { isAuthenticated, userEmail, userRole, isLoading };
};

export default UseAuthStatus;