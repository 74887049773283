import React, {useState} from "react";
import { upperFirst } from "../Helpers/TextHelpers";

const SearchResults = ({
  searchResponse = { tickets: [], pagination: { totalItems: 0, totalPages: 0, currentPage: 0, pageSize: 25 } },
  navigate,
  onPageChange,
}) => {

  const { tickets: results, pagination } = searchResponse;
  const { totalItems, totalPages, currentPage, pageSize } = pagination;
  
  console.log("tickets in searchResults", results);

  if (!results || results.length === 0){
    return "No results found that match your search";
  }

  const MAX_PAGE_DISPLAY = 5; // Max number of pagination links to display

  // Function to calculate range of pages to display
  const paginationRange = (currentPage, totalPages) => {
    let start = currentPage - Math.floor(MAX_PAGE_DISPLAY / 2);
    start = Math.max(start, 1);
    let end = start + MAX_PAGE_DISPLAY - 1;
    end = Math.min(end, totalPages);

    // Adjust start if end is at the limit
    if (end - start + 1 < MAX_PAGE_DISPLAY) {
      start = Math.max(end - MAX_PAGE_DISPLAY + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => i + start);
  };

  return (
    <>
      { totalItems === 0 
        ?
        <p className="notification is-warning">No results found that match your search, please try again.</p>
        :
        <p className="notification is-success has-text-weight-bold">Found {totalItems} results. You are on page {currentPage} of {totalPages}.</p>
      }

      {pagination && pagination.totalPages > 1 && (
        <nav
          className="pagination is-centered"
          role="navigation"
          aria-label="pagination"
        >
          
            <button
              className="pagination-previous button is-link"
              onClick={() => onPageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage <= 1}
            >
              Previous
            </button>
          

          {pagination.currentPage < pagination.totalPages && (
            <button
              className="pagination-next button is-link"
              onClick={() => onPageChange(pagination.currentPage + 1)}
            >
              Next
            </button>
          )}

          <ul className="pagination-list">
            {paginationRange(pagination.currentPage, pagination.totalPages).map((n) => (
              <li key={n}>
                <button
                  className={`pagination-link ${
                    pagination.currentPage === n ? "is-current" : ""
                  }`}
                  onClick={() => onPageChange(n)}
                >
                  {n}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}

      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Site</th>
            <th>Ticket ID</th>
            <th>Plate</th>
            <th>Barcode</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td className="is-vcentered">
                {result.siteId.name ?? 'ERROR'}
              </td>
              <td style={{ height: "75px" }} className="is-vcentered">
                <a
                  href={`/ticket/${result.siteId._id}/${result.identifier}`}
                  className="has-text-weight-bold is-size-5"
                >
                  {result.identifier}
                </a>
              </td>
              <td className="is-vcentered">
                {result.medias && result.medias.filter((media) => media.type === "plate").length > 0
                  ? result.medias
                      .filter((media) => media.type === "plate")
                      .map((media, mediaIndex) => (
                        <p key={mediaIndex}>
                          <span className="tag is-small is-warning plate-number">
                            {media.identifier}
                          </span>
                        </p>
                      ))
                  : <em className="has-text-grey">Not set</em>}
              </td>
              <td className="is-vcentered">
                {result.medias && result.medias.filter((media) => media.type === "barcode").length > 0
                  ? result.medias
                      .filter((media) => media.type === "barcode")
                      .map((media, mediaIndex) => (
                        <p key={mediaIndex} className="barcode">
                          {media.identifier}
                        </p>
                      ))
                  : <em className="has-text-grey">Not set</em>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {pagination && pagination.totalPages > 1 && (
        <nav
          className="pagination is-centered"
          role="navigation"
          aria-label="pagination"
        >
          <button
            className="pagination-previous button is-link"
            onClick={() => onPageChange(pagination.currentPage - 1)}
            disabled={pagination.currentPage <= 1}
          >
            Previous
          </button>

          {pagination.currentPage < pagination.totalPages && (
            <button
              className="pagination-next button is-link"
              onClick={() => onPageChange(pagination.currentPage + 1)}
            >
              Next
            </button>
          )}

          <ul className="pagination-list">
            {paginationRange(pagination.currentPage, pagination.totalPages).map((n) => (
              <li key={n}>
                <button
                  className={`pagination-link ${
                    pagination.currentPage === n ? "is-current" : ""
                  }`}
                  onClick={() => onPageChange(n)}
                >
                  {n}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </>
  );
};

export default SearchResults;
