import React from 'react';
import './FullScreenLoader.css';

const FullScreenLoader = ({ isFullScreen = true }) => (
  <div className={isFullScreen ? "fullscreen-loader" : "container-loader"}>
    <div className="spinner"></div>
  </div>
);

export default FullScreenLoader;
