import React from "react";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  return (
    <div className="columns" style={{ minHeight: "100vh" }}>
      <div className="column is-3 has-background-info-light">
        <Sidebar />
      </div>
      <div className="column">
        <div className="container">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
