import LoginPage from '../Components/LoginPage';
import Search from '../Components/SearchPage';
import PasswordReset from '../Components/PasswordResetPage';
import TicketPage from '../Components/TicketPage';
import SitesPage from '../Components/SitesPage';
import SiteEditPage from '../Components/SiteEditPage';
import UsersPage from '../Components/UsersPage';
import UserEditPage from '../Components/UserEditPage';
import AuditLogTable from '../Components/AuditLogTable';
import UserAuditLogTable from '../Components/UserAuditLogTable';
import SearchHistory from '../Components/SearchHistory';

// Public routes that don't require authentication
export const publicRoutes = [
  { path: "/", element: <LoginPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/password-reset", element: <PasswordReset /> },
];

// Protected routes that require authentication
export const protectedRoutes = [
  { path: "/search", element: Search },
  { path: "/ticket/:siteId/:ticketIdentifier", element: TicketPage },
  { path: "/activity", element: UserAuditLogTable },
  { path: "/history", element: SearchHistory },
  { path: "/admin/sites", element: SitesPage },
  { path: "/admin/site/:id", element: SiteEditPage },
  { path: "/admin/users", element: UsersPage },
  { path: "/admin/users/:id", element: UserEditPage },
  { path: "/admin/logs", element: AuditLogTable },
];