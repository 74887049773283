import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import { toast } from "react-toastify";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [currentUserToDelete, setCurrentUserToDelete] = useState(null);
  const [newUser, setNewUser] = useState({
    email: "",
    role: "",
    sites: [],
  });

  // Define fetchSites function outside of useEffect
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_API_ADDRESS}/users`,
        { withCredentials: true }
      );
      setUsers(usersResponse.data);

      // Fetching sites only if needed for assigning to users
      const sitesResponse = await axios.get(
        `${process.env.REACT_APP_API_ADDRESS}/sites`,
        { withCredentials: true }
      );
      setAllSites(sitesResponse.data.sites);
    } catch (err) {
      console.error("Failed to fetch data:", err);
      setError("Failed to load data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSiteChange = (siteId) => {
    const updatedSites = newUser.sites.includes(siteId)
      ? newUser.sites.filter((id) => id !== siteId)
      : [...newUser.sites, siteId];
    setNewUser({ ...newUser, sites: updatedSites });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_ADDRESS}/users`, newUser, {
        withCredentials: true,
      });
      setIsModalActive(false);
      toast.success("User added successfully");
      fetchUsers();
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleDeleteUserClick = (user) => {
    setCurrentUserToDelete(user);
    setIsDeleteUserModalOpen(true);
  };

  const handleDeleteUser = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_ADDRESS}/users/${currentUserToDelete._id}`,
        {
          withCredentials: true,
        }
      );
      setIsDeleteUserModalOpen(false); // Close the modal
      fetchUsers(); // Refresh the sites
      toast.success("User deleted successfully");
    } catch (err) {
      console.error("Failed to delete user:", err);
      toast.error("Failed to delete user", err.message);
    }
  };

  return (
    <Layout>
      {isDeleteUserModalOpen && (
        <div className="modal is-active">
          <div
            className="modal-background"
            onClick={() => setIsDeleteUserModalOpen(false)}
          ></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Delete User Confirmation: {currentUserToDelete.email}
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => setIsDeleteUserModalOpen(false)}
              ></button>
            </header>
            <section className="modal-card-body">
              <p>
                Are you sure you want to delete this user? This action cannot be
                undone.
              </p>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-danger" onClick={handleDeleteUser}>
                Delete
              </button>
              <button
                className="button"
                onClick={() => setIsDeleteUserModalOpen(false)}
              >
                Cancel
              </button>
            </footer>
          </div>
        </div>
      )}

      <div className="section">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h3 className="title is-4">Users</h3>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button
                  className="button is-info"
                  onClick={() => setIsModalActive(true)}
                >
                  Add user
                </button>
              </div>
            </div>
          </div>

          <div className={`modal ${isModalActive ? "is-active" : ""}`}>
            <div
              className="modal-background"
              onClick={() => setIsModalActive(false)}
            ></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Add New User</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => setIsModalActive(false)}
                ></button>
              </header>
              <section className="modal-card-body">
                <form onSubmit={handleAddUser}>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        className="input"
                        type="email"
                        name="email"
                        value={newUser.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Role</label>
                    <div className="control">
                      <div className="select">
                        <select
                          name="role"
                          value={newUser.role}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Role</option>
                          <option value="admin">Admin</option>
                          <option value="user">User</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {newUser.role !== "admin" && (
                    <div className="field">
                      <label className="label">Sites</label>
                      {allSites.map((site) => (
                        <label key={site._id} className="checkbox">
                          <input
                            type="checkbox"
                            checked={newUser.sites.includes(site._id)}
                            onChange={() => handleSiteChange(site._id)}
                          />{" "}
                          {site.name}
                        </label>
                      ))}
                    </div>
                  )}
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-link">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>

          {isLoading ? (
            <FullScreenLoader />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Sites</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td>
                      <Link to={`/admin/users/${user._id}`}>{user.email}</Link>
                    </td>
                    <td>{user.role}</td>
                    <td>
                      {user.sites.map((site) => site.name).join(', ')}
                    </td>
                    <td>
                      <button
                        className="button is-danger is-small is-outlined"
                        onClick={() => handleDeleteUserClick(user)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default UsersPage;
