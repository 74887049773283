import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import axios from "axios";
import SearchResults from "./SearchResults";
import CustomCheckbox from "./CustomCheckbox";
import { toast } from "react-toastify";

const SearchPage = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [returnFirstMatch, setReturnFirstMatch] = useState(true);
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchResponse, setSearchResponse] = useState({ tickets: [], pagination: {} });

  const navigate = useNavigate();

  // Function to sanitize and limit query input
  const sanitizeInput = (input) => {
    // Remove special characters except spaces and alphanumeric
    const sanitized = input.replace(/[^a-zA-Z0-9\s]/g, "");
    // Limit the length to 24 characters
    return sanitized.slice(0, 24);
  };

  const handlePageChange = (newPage) => {
    handleSearch(newPage);
  };

  const handleSearch = async (pageNumber = 1) => {
    const sanitizedQuery = sanitizeInput(query);

    if (sanitizedQuery.length < 3) {
      setErrorMessage("Enter at least 3 characters of a Customer ID, Plate Number, or Barcode. Enter the full ticket ID for the best performance.");
      return; // Exit the function early
    }

    setLoading(true);
    setSearchAttempted(true);
    setErrorMessage(""); // Clear error message when a valid search is initiated

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/search`,
        {},
        {
          withCredentials: true,
          params: {    
            searchString: sanitizedQuery,
            pageSize: 25,
            returnFirstMatch,
            page: pageNumber,
          },
          headers: {
            Accept: "application/json"
          },
        }
      );

      console.log(response);

      const { tickets, pagination } = response.data;

      if (returnFirstMatch && tickets) {
        console.log("Going to first match:", `/ticket/${tickets.siteId._id}/${tickets.identifier}`);
        navigate(`/ticket/${tickets.siteId._id}/${tickets.identifier}`);
      } else {
        setSearchResponse({ tickets, pagination }); // Setting state with the tickets and pagination
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setErrorMessage("Failed to fetch search results. " + error.message + ". Please try again or contact support.");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle key down event
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action to avoid any unwanted behavior
      handleSearch();
    }
  };

  return (
    <Layout>
      <div className="section">
        <div className="container">
          <div className="field">
            <CustomCheckbox
              id="returnFirstMatch"
              label="Automatically load the first ticket to match my search"
              checked={returnFirstMatch}
              onChange={() => setReturnFirstMatch(!returnFirstMatch)}
            />
          </div>
          <div className="field has-addons" style={{ justifyContent: "center" }}>
            <div className="control is-expanded">
              <input
                className="input is-large"
                type="text"
                placeholder="Search by Merlin Customer ID or Plate Number..."
                value={query}
                onChange={(e) => setQuery(sanitizeInput(e.target.value))}
                onKeyDown={handleKeyDown}
                autoFocus
                maxLength="24" // Limit the input to 24 characters
              />
            </div>
            <div className="control">
              <button
                className={`button is-info is-large ${loading ? "is-loading" : ""}`}
                style={{ width: "150px" }}
                onClick={handleSearch}
              >
                {loading ? "" : "Search"}
              </button>
            </div>
          </div>
          {errorMessage && (
            <p className="notification is-danger">{errorMessage}</p>
          )}
          {!loading && searchAttempted && searchResponse && searchResponse.tickets && searchResponse.tickets.length && searchResponse.tickets.length > 0 ? (
            <>
            test
            <SearchResults searchResponse={searchResponse} navigate={navigate} onPageChange={handlePageChange} />
            </>
          ) : (
            searchAttempted && !loading && (
              <p className="notification is-danger">
                No results found that match your search criteria. Ensure you have access to at least 1 site and try again.
              </p>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SearchPage;