import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes, protectedRoutes } from './Config/Routes';
import ProtectedRoute from './Components/ProtectedRoute'; // Assuming ProtectedRoute is saved here
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <ToastContainer
      autoClose={3000}
      limit={3}
      position="bottom-right"
      closeOnClick
      pauseOnHover      
    />
    <Router>
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {protectedRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={
            <ProtectedRoute element={route.element} />
          } />
        ))}
      </Routes>
    </Router>
    </>
  );
}

export default App;
